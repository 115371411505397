import { defineStore } from 'pinia';
import { useToast } from 'vue-toastification';

import { pinia } from '@/main';
import main from '@/main';

const toast = useToast();

interface SocketStore {
  // 连接状态
  isConnected: boolean;
  // 消息内容
  message: string;
  // 重新连接错误
  reconnectError: boolean;
  // 心跳消息发送时间
  heartBeatInterval: number;
  // 心跳定时器
  heartBeatTimer: number;
}

export const useSocketStore = defineStore({
  id: 'socket',
  state: (): SocketStore => ({
    // 连接状态
    isConnected: false,
    // 消息内容
    message: '',
    // 重新连接错误
    reconnectError: false,
    // 心跳消息发送时间
    heartBeatInterval: 10000,
    // 心跳定时器
    heartBeatTimer: 0,
  }),
  actions: {
    // 连接打开
    SOCKET_ONOPEN(event: Event) {
      main.config.globalProperties.$socket = event.currentTarget;
      this.isConnected = true;
      // 连接成功时启动定时发送心跳消息，避免被服务器断开连接
      this.heartBeatTimer = window.setInterval(() => {
        const message = 'ping';
        this.isConnected &&
        main.config.globalProperties.$socket.send(message);
      }, this.heartBeatInterval);
    },
    // 连接关闭
    SOCKET_ONCLOSE(event: Event) {
      this.isConnected = false;
      // 连接关闭时停掉心跳消息
      window.clearInterval(this.heartBeatTimer);
      this.heartBeatTimer = 0;
      console.log('Connection closed. ' + new Date().toLocaleString());
      console.log(event);
    },
    // 发生错误
    SOCKET_ONERROR(event: Event) {
      toast.error('连接服务器失败，请检查网络连接或联系管理员');
      console.error(event);
    },
    // 收到服务端发送的消息
    SOCKET_ONMESSAGE(message: string) {
      this.message = message;
    },
    // 自动重连
    SOCKET_RECONNECT(count: number) {
      console.info('try to reconnect: ' + count);
    },
    // 重连错误
    SOCKET_RECONNECT_ERROR() {
      this.reconnectError = true;
    },
  },
});

// Need to be used outside the setup
export function useSocketStoreWithOut() {
  return useSocketStore(pinia);
}
