import axios, {AxiosInstance, AxiosRequestConfig, Method} from 'axios';

import {useUserStore} from '@/store/user';
import {Convert} from "@/app/entity/TokenEntity";
import {storeToken} from "@/app/utils/store";
import createAuthRefreshInterceptor from "axios-auth-refresh";

/**
 * Base API class, all API classes should call this class to send request
 *
 * This class is a wrapper of axios, it provides some useful methods to send request
 *
 * The default baseURL is https://api.ultikits.com
 *
 * The default timeout is 5000ms
 *
 * Some properties and logic are hard-coded for the UltiKits api, so you can't use it to send request to other api.
 *
 * The documentation of the UltiKits api is here: https://wisdomme.gitbook.io/ultikits-panel
 *
 * To make a request, you need to call the following methods:
 * - `withPath(path: string)` to set the path of the request
 * - `withParams(params: any)` to set the params of the request
 * - `withMethod(method: Method)` to set the method of the request
 * - `withToken()` if you want to send the request with token
 * - `request()` to send the request, it will return a promise
 *
 * > Note: you should call withPath() and withMethod() at least once before calling request()
 *
 * It is recommended to convert the response to an entity class, There are some entity classes in `src\app\entity`,
 * and you can use `Convert.toXXXEntity()` to convert the response to an entity class.
 *
 * You can also create your own entity class, just create an interface with the same structure as the response, and add the `Convert.toXXXEntity()` method.
 *
 *
 * @example ``` typescript
 * const api = new API();
 *   api
 *     .withPath('/user/getToken')
 *     .withParams({ username: username, password: password })
 *     .withMethod("POST")
 *     .request()
 *     .then((response) => {
 *       if (response.data != '') {
 *         resolve(Convert.toTokenEntity(response.data));
 *       } else {
 *         reject(response.data);
 *       }
 *     }, (reason) => {
 *       reject(reason);
 *   });
 * ```
 *
 * @class API
 * @author qianmo
 * @version 2.0.0
 * @since 2.0.0
 */
export class API {
  private readonly instance: AxiosInstance;

  private path!: string | null;
  private params: any | null;
  private method!: Method | null;
  private data: any | null;

  private readonly baseURL = "https://api.ultikits.com"
  private readonly timeout = 5000

  private userStore = useUserStore();

  /**
   * @constructor Create an instance of API
   *
   * You can create an instance of API by calling `new API()` or `API.getInstance()`
   */
  constructor() {
    this.instance = axios.create();
  }

  withToken(): API {
    this.instance.defaults.headers.common['Authorization'] = 'Bearer ' + this.userStore.auth.token.access;
    createAuthRefreshInterceptor(this.instance, (failedRequest) => {
      return axios.post(`${this.baseURL}/user/refreshToken`, {}, {
        params: { "refresh_token": localStorage.getItem("refresh_token") }
      }).then((response) => {
        storeToken(Convert.toTokenEntity(response.data));
        failedRequest.response.config.headers['Authorization'] = `Bearer ${response.data.access_token}`;
        return Promise.resolve();
      }, () => {
        this.userStore.updateAuthStatus(false);
        return Promise.reject();
      });
    });
    return this;
  }

  withPath(path: string): API {
    this.path = path;
    return this;
  }

  withParams(params: any): API {
    this.params = params;
    return this;
  }

  withBody(body: any): API {
    this.data = body;
    return this;
  }

  /**
   * Set the method of the request
   *
   * The method can be one of the following:
   * - GET (in common use)
   * - POST (in common use)
   * - ...
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods
   *
   *
   * @param method
   */
  withMethod(method: Method): API {
    this.method = method;
    return this;
  }

  request(): Promise<any> {
    if (!this.method) {
      throw new Error('HTTP method is not set. Call withMethod() before request().');
    }

    if (!this.path) {
      throw new Error('Path is not set. Call withPath() before request().');
    }

    const requestConfig: AxiosRequestConfig = {
      url: `${this.baseURL}${this.path}`,
      timeout: this.timeout,
      method: this.method,
      params: this.params,
      data: this.data
    };

    return this.instance.request(requestConfig);
  }
}
