<template>
  <v-container fluid class="mb-8">
    <email-validation />
    <v-row justify="center">
      <v-col cols="12" lg="6" md="8" sm="12">
        <v-row>
          <v-col cols="12" :class="$vuetify.display.mobile? '' : 'mt-12'">
            <h1 class="mt-12 mb-12">欢迎来到 UltiKits!</h1>
          </v-col>
        </v-row>
        <v-row class="mt-12">
          <home-tab-one/>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import HomeTabOne from "@/components/HomeTabOne.vue";
import {useAppStore} from "@/store/app";
import EmailValidation from "@/components/EmailValidation.vue";

export default defineComponent({
  name: "index",
  components: {EmailValidation, HomeTabOne},
  data: () => {
    return {
    }
  },
  mounted() {
    const appStore = useAppStore()

    appStore.setLoading(false)
  }
})
</script>

<route lang="yaml">
meta:
  requiresAuth: true
</route>
