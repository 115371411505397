const __pages_import_0__ = () => import("/src/views/server/index.vue");
const __pages_import_1__ = () => import("/src/views/module/index.vue");
const __pages_import_2__ = () => import("/src/views/auth/reset.vue");
const __pages_import_3__ = () => import("/src/views/auth/register.vue");
const __pages_import_4__ = () => import("/src/views/auth/login.vue");
const __pages_import_5__ = () => import("/src/views/auth/index.vue");
const __pages_import_6__ = () => import("/src/views/profile.vue");
const __pages_import_7__ = () => import("/src/views/member.vue");
const __pages_import_8__ = () => import("/src/views/manager.vue");
import __pages_import_9__ from "/src/views/index.vue";
const __pages_import_10__ = () => import("/src/views/developer.vue");
const __pages_import_11__ = () => import("/src/views/module/[id].vue");
const __pages_import_12__ = () => import("/src/views/server/[id].vue");

const routes = [{"name":"server","path":"/server","component":__pages_import_0__,"props":true,"meta":{"requiresAuth":true}},{"name":"module","path":"/module","component":__pages_import_1__,"props":true,"meta":{"requiresAuth":true,"layout":"default"}},{"name":"auth-reset","path":"/auth/reset","component":__pages_import_2__,"props":true,"meta":{"requiresAuth":false,"layout":"auth"}},{"name":"auth-register","path":"/auth/register","component":__pages_import_3__,"props":true,"meta":{"requiresAuth":false,"layout":"auth"}},{"name":"auth-login","path":"/auth/login","component":__pages_import_4__,"props":true,"meta":{"requiresAuth":false,"layout":"auth"}},{"name":"auth","path":"/auth","component":__pages_import_5__,"props":true,"meta":{"requiresAuth":false,"layout":"auth"}},{"name":"profile","path":"/profile","component":__pages_import_6__,"props":true,"meta":{"requiresAuth":true,"layout":"default"}},{"name":"member","path":"/member","component":__pages_import_7__,"props":true},{"name":"manager","path":"/manager","component":__pages_import_8__,"props":true,"meta":{"requiresAuth":true,"layout":"manager"}},{"name":"index","path":"/","component":__pages_import_9__,"props":true,"meta":{"requiresAuth":true}},{"name":"developer","path":"/developer","component":__pages_import_10__,"props":true,"meta":{"requiresAuth":true,"layout":"default"}},{"name":"module-id","path":"/module/:id","component":__pages_import_11__,"props":true,"meta":{"requiresAuth":true,"layout":"default"}},{"name":"server-id","path":"/server/:id","component":__pages_import_12__,"props":true,"meta":{"requiresAuth":true,"layout":"default"}}];

export default routes;