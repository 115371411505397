import { useUserStore } from "@/store/user";
import { TokenEntity } from "@/app/entity/TokenEntity";
import {UserEntity} from "@/app/entity/UserEntity";

function storeToken(tokenEntity: TokenEntity) {
  const userStore = useUserStore()

  userStore.updateAccessToken(tokenEntity.access_token)
  userStore.updateRefreshToken(tokenEntity.refresh_token)
  userStore.updateUserId(tokenEntity.id)

  localStorage.setItem('access_token', tokenEntity.access_token)
  localStorage.setItem('refresh_token', tokenEntity.refresh_token)
  localStorage.setItem('id', String(tokenEntity.id))
}

function removeToken() {
  const userStore = useUserStore()

  userStore.updateAccessToken('')
  userStore.updateRefreshToken('')
  userStore.updateUserId(0)

  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  localStorage.removeItem('id')
}

function storeUser(userEntity: UserEntity) {
  const userStore = useUserStore()

  userStore.updateUsername(userEntity.username)
  userStore.updateProMembershipStatus(Boolean(userEntity.pro))
  userStore.updateEmail(userEntity.email)
  userStore.updateEmailStatus(Boolean(userEntity.emailValidated))
  userStore.updateJoinDate(userEntity.joinDate)
  userStore.updateProMembershipStatus(Boolean(userEntity.pro))
  userStore.updateMaxMembership(userEntity.maxServer)
  userStore.updateMembershipExpiry(userEntity.proExpiryDate)
}

function removeUser() {
  const userStore = useUserStore()

  userStore.updateUsername('')
  userStore.updateProMembershipStatus(false)
  userStore.updateEmail('')
  userStore.updateEmailStatus(false)
  userStore.updateJoinDate(0)
  userStore.updateProMembershipStatus(false)
  userStore.updateMaxMembership(0)
  userStore.updateMembershipExpiry(0)
}

export {storeToken, storeUser, removeToken, removeUser}
