export interface TokenEntity {
  access_token:  string;
  token_type:    string;
  refresh_token: string;
  expires_in:    number;
  scope:         string;
  id:            number;
  jti:           string;
}

export class Convert {
  public static toTokenEntity(json: any): TokenEntity {
    return json as TokenEntity;
  }

  public static tokenEntityToJson(value: TokenEntity): string {
    return JSON.stringify(value, null, 2);
  }
}
