/**
 * main.ts
 *
 * Bootstraps plugins then mounts the App`
 */

import App from './App.vue'

import { createApp } from 'vue'

import type { App as VueApp } from 'vue'

import {createRouter, createWebHistory} from "vue-router";
import {setupLayouts} from "virtual:generated-layouts";
import generatedRoutes from "virtual:generated-pages";
import {createVuetify} from "vuetify";
import {VDataTable, VDataTableServer} from "vuetify/labs/VDataTable";
import {VDataIterator} from "vuetify/labs/VDataIterator";
import {VStepper} from "vuetify/labs/VStepper";
import {VOtpInput} from "vuetify/labs/VOtpInput";

import {createPinia} from "pinia";
import piniaPersist from "pinia-plugin-persist";
import toast from "vue-toastification";
import VueTheMask from "vue-the-mask";
import VueNativeSock from "vue-native-websocket-vue3";
import VueGtag from "vue-gtag";
import Particles from "@tsparticles/vue3";
import {loadFull} from "tsparticles"
import * as Sentry from "@sentry/vue";

import {routerGuard} from "@/app/utils/guard";
import { useSocketStoreWithOut } from "@/store/sock";

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import "vue-toastification/dist/index.css";
import '@/styles/main.css'
import {en, zhHans} from "vuetify/locale";
import zh_CN from '@/locales/zh_CN'
import en_US from "@/locales/en_US";

const app = createApp(App)

const router = createRouter({
  history: createWebHistory(),
  routes: setupLayouts(generatedRoutes),
});

const toastOptions = {
  showCloseButtonOnHover: true,
  transition: "Vue-Toastification__fade",
  newestOnTop: false,
}

export const pinia = createPinia().use(piniaPersist)
const piniaSocketStore = useSocketStoreWithOut();

async function loadFonts () {
  const webFontLoader = await import('webfontloader')

  webFontLoader.load({
    google: {
      api: 'https://fonts.googlefonts.cn/css',
      families: ['Roboto:100,300,400,500,700,900&display=swap'],
    },
  })
}

function vuetify () {
  return  createVuetify({
    components: {
      VDataTable,
      VDataIterator,
      VStepper,
      VOtpInput,
      VDataTableServer
    },
    locale: {
      locale: 'zhHans',
      fallback: 'en',
      messages: { zhHans, en, zh_CN, en_US },
    },
  })
}

const WebSocketConfig = {
    store: piniaSocketStore,
    // 数据发送/接收使用使用json
    format: "json",
    // 开启手动调用 connect() 连接服务器
    connectManually: true,
    // 关闭自动重连，使用手动重连
    reconnection: false,
    // 尝试重连的次数
    reconnectionAttempts: 0,
    // 重连间隔时间
    reconnectionDelay: 0
}

function registerPlugins (app: VueApp) {
  loadFonts().then(() => {})
  app
    .use(vuetify())
    .use(pinia)
    .use(router)
    .use((app) => routerGuard(app, router))
    .use(toast, toastOptions)
    .use(VueTheMask)
    .use(VueNativeSock, 'wss://api.ultikits.com/ws', WebSocketConfig)
      .use(VueGtag, {
      config: { id: "G-3W1JWB0357" },
        appName: 'UltiKits',
        pageTrackerScreenviewEnabled: true,
        pageTrackerExcludedRoutes: [
          '/auth',
          'route_name_value'
        ],
    }, router)
    .use(Particles, {
      init: async engine => {
        await loadFull(engine)
      }
    })
}

Sentry.init({
  app,
  dsn: "https://f25ffe572ef72ec06c0f78f4041510b4@o4507592506408960.ingest.us.sentry.io/4507592509292544",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.directive('decimal-input', {
  mounted(el) {
    el.setAttribute('mask', '#.#'); // 设置掩码，只允许输入小数，去掉多余的零
    el.setAttribute('placeholder', '0'); // 设置占位符
  },
});

registerPlugins(app)

app.mount('#app')

export default app
