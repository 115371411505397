export default {
  app: {
    auth: {
      welcome: 'Welcome back',
      username: 'Username',
      password: 'Password',
      register: 'Register account',
      forget: 'Forgot password',
      email: 'Email',
      pwdRepeat: 'Repeat password',
      backLogin: 'Back to login',
      findAccount: 'Retrieve account',
      account: 'Account',
      usernameOrEmail: 'Username or email',
      plzInputAccount: 'Please enter the account to retrieve',
      verify: 'Verify',
      codeVerify: 'Identity verification',
      codeSendTo: 'Verification code has been sent to',
      codeSent: 'Verification code has been sent, please check',
      codeSendFail: 'Failed to send verification code:',
      reSend: 'Resend',
      codeVerified: 'Identity verified successfully',
      verifyFailure: 'Verification failed:',
      resetPwd: 'Reset password',
      newPwd: 'New password',
      newPwdRepeat: 'Repeat new password',
      verifyExpire: 'Identity verification is valid until',
      resetHint: 'Please complete the password reset operation before this time',
      resetSuccess: 'Password reset successful, please login with the new password',
      resetFailure: 'Password reset failed: ',
      requiredField: 'This field is required',
      pwdRequireMoreThan: 'Password must be more than %n characters',
      pwdRequireOnlyAscii: 'Password can only contain letters, numbers, and underscores',
      pwdNotMatch: 'The passwords do not match',
      subTitle: 'Spigot server plugin management platform',
      profileFailure: 'Login failed: Unable to retrieve user information, please try again later',
      loginSuccess: 'Login successful: Welcome back,',
      loginFailure: 'Login failed:',
      badCredential: 'Login failed: Incorrect username or password',
      usernameRequireMoreThan: 'Username must be more than {0} characters',
      usernameRequireLessThan: 'Username must be less than {0} characters',
      usernameRequireOnlyAscii: 'Username can only contain letters, numbers, and underscores',
      requireAvailableEmail: 'Please enter a valid email',
      registerSuccess: 'Registration successful, please login',
      registerFailure: 'Registration failed:'
    },
    appBar: {
      server: 'Servers',
      member: 'Member',
      module: 'Modules',
      dev: 'Developer',
      profile: 'My profile',
      logout: 'Logout',
      logoutMsg: 'You have been logged out',
    }
  },
  badge: 'Badge',
  open: 'Open',
  close: 'Close',
  dataIterator: {
    noResultsText: 'No matching results',
    loadingText: 'Loading...'
  },
  dataTable: {
    itemsPerPageText: 'Items per page:',
    ariaLabel: {
      sortDescending: ': Sorted descending.',
      sortAscending: ': Sorted ascending.',
      sortNone: ': Not sorted.',
      activateNone: 'Click to remove sorting.',
      activateDescending: 'Click to sort descending.',
      activateAscending: 'Click to sort ascending.'
    },
    sortBy: 'Sort by'
  },
  dataFooter: {
    itemsPerPageText: 'Items per page:',
    itemsPerPageAll: 'All',
    nextPage: 'Next page',
    prevPage: 'Previous page',
    firstPage: 'First page',
    lastPage: 'Last page',
    pageText: '{0}-{1} of {2}'
  },
  dateRangeInput: {
    divider: 'to'
  },
  datePicker: {
    ok: 'OK',
    cancel: 'Cancel',
    range: {
      title: 'Select date',
      header: 'Enter date'
    },
    title: 'Select date',
    header: 'Enter date',
    input: {
      placeholder: 'Enter date'
    }
  },
  noDataText: 'No data',
  carousel: {
    prev: 'Previous',
    next: 'Next',
    ariaLabel: {
      delimiter: 'Carousel {0} of {1}'
    }
  },
  calendar: {
    moreEvents: '{0} more'
  },
  input: {
    clear: 'Clear {0}',
    prependAction: '{0} prepend action',
    appendAction: '{0} append action',
    otp: 'Please enter OTP character {0}'
  },
  fileInput: {
    counter: '{0} files',
    counterSize: '{0} files ({1} in total)'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  },
  pagination: {
    ariaLabel: {
      root: 'Pagination navigation',
      next: 'Next page',
      previous: 'Previous page',
      page: 'Go to page {0}',
      currentPage: 'Current page {0}',
      first: 'First page',
      last: 'Last page'
    }
  },
  stepper: {
    next: 'Next step',
    prev: 'Previous step'
  },
  rating: {
    ariaLabel: {
      item: 'Rating {0} of {1}'
    }
  },
  loading: 'Loading...',
  infiniteScroll: {
    loadMore: 'Load more',
    empty: 'No more'
  }
};
