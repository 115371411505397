<template>
  <v-app-bar flat id="AppBar" :class="isOnTop ? 'AppBarOnTop' : ''">
    <transition name="scroll-x-reverse-transition" mode="out-in">
      <v-btn v-if="!appStore.getNav && $vuetify.display.mobile" icon @click="goBackOrToDefault">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-app-bar-title v-else-if="$vuetify.display.mobile">
        <span class="font-weight-bold">ULTIKITS</span>
      </v-app-bar-title>
      <v-btn v-else @click="$router.push('/')" class="ml-4 mr-4 mt-1">
        <h3>ULTIKITS</h3>
      </v-btn>
    </transition>
    <v-btn v-if="$vuetify.display.mdAndUp" prepend-icon="mdi-server" to="/server" class="mr-1">{{ t('$vuetify.app.appBar.server') }}</v-btn>
    <v-btn v-if="$vuetify.display.mdAndUp" prepend-icon="mdi-crown" to="/member" class="mr-1">{{ t('$vuetify.app.appBar.member') }}</v-btn>
    <v-btn v-if="$vuetify.display.mdAndUp" prepend-icon="mdi-package" to="/module" class="mr-1">{{ t('$vuetify.app.appBar.module') }}</v-btn>
    <v-btn v-if="$vuetify.display.mdAndUp" prepend-icon="mdi-xml" to="/developer" class="mr-1">{{ t('$vuetify.app.appBar.dev') }}</v-btn>
    <v-spacer/>
    <v-btn icon="mdi-plus"></v-btn>
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" prepend-icon="mdi-account" append-icon="mdi-menu-down">{{ userStore.getUsername }}</v-btn>
      </template>
      <v-list density="compact" :nav="true">
        <v-list-item link to="/profile">
          <v-list-item-title>{{ t('$vuetify.app.appBar.profile') }}</v-list-item-title>
        </v-list-item>
        <v-divider class="mb-1"/>
        <v-list-item @click="logout" :link="true" color="red" append-icon="mdi-logout" :title="t('$vuetify.app.appBar.logout')"/>
      </v-list>
    </v-menu>
    <v-expand-transition>
      <v-progress-linear
        v-if="appStore.getBl"
        :active="appStore.getBl"
        :indeterminate="appStore.getBl"
        absolute
        bottom
        color="indigo"
      ></v-progress-linear>
    </v-expand-transition>
  </v-app-bar>
</template>

<script lang="ts" setup>
  import {onMounted, ref} from "vue";
  import {useGuard} from "@this-dot/vue-route-guard";

  import {useUserStore} from "@/store/user";
  import {removeToken, removeUser} from "@/app/utils/store";
  import {useAppStore} from "@/store/app";
  import {useToast} from "vue-toastification";
  import {useRouter} from "vue-router";
  import { Crisp } from "crisp-sdk-web"
  import {useLocale} from "vuetify";

  const userStore = useUserStore()
  const appStore = useAppStore()

  const auth = useGuard()
  const toast = useToast()
  const router = useRouter()
  const { t } = useLocale()

  const isOnTop = ref(true)

  const logout = () => {
    appStore.setLoading(true)
    auth.clearAuthentication().then(() => {
      removeToken()
      removeUser()
      Crisp.setTokenId();
      Crisp.session.reset();
      appStore.setRedirect("/")
      appStore.setLoading(false)
      toast.success(t('$vuetify.app.appBar.logoutMsg'))
    })
  }

  window.addEventListener("scroll", () => {
    isOnTop.value = window.scrollY <= 0
  });

  function goBackOrToDefault() {
    // 使用 $router.go(-1) 尝试返回上一页
    router.go(-1)
    appStore.setNav(true)

    // 检查是否成功返回，如果路由不变，说明没有前一页
    if (router.currentRoute.value.path === router.history.current.path) {
      // 没有前一页，返回到默认页面
      router.replace('/')
      appStore.setNav(true)
    }
  }
</script>
