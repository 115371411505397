<script setup lang="ts">
import {useAppStore} from "@/store/app";

const appStore = useAppStore()

</script>

<template>
  <v-overlay
    persistent
    :model-value="appStore.isLoading"
    class="align-center justify-center loading-overlay"
    :theme="$vuetify.theme.global.name == 'dark' ? 'light' : 'dark'"
  >
    <v-progress-circular indeterminate width="8" size="64"/>
  </v-overlay>
</template>

<style scoped>

</style>
