import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => ({
    auth: {
      status: false,
      token: {
        access: '',
        refresh: '',
      },
    },
    email: {
      address: '',
      validated: false,
    },
    profile: {
      id: 0,
      username: '',
      join: 0,
    },
    member: {
      pro: false,
      exp: 0,
      max: 0,
    },
  }),
  getters: {
    isAuthenticated(state) {
      return state.auth.status;
    },
    getAccessToken(state) {
      return state.auth.token.access;
    },
    getRefreshToken(state) {
      return state.auth.token.refresh;
    },
    getEmail(state) {
      return state.email.address;
    },
    isEmailValidated(state) {
      return state.email.validated;
    },
    getUserId(state) {
      return state.profile.id;
    },
    getUsername(state) {
      return state.profile.username;
    },
    getJoinDate(state) {
      return state.profile.join;
    },
    isProMember(state) {
      return state.member.pro;
    },
    getMembershipExpiry(state) {
      return state.member.exp;
    },
    getMaxMembership(state) {
      return state.member.max;
    },
  },
  actions: {
    updateEmail(newEmail: string) {
      this.email.address = newEmail;
    },
    updateUsername(newUsername: string) {
      this.profile.username = newUsername;
    },
    updateAuthStatus(status: boolean) {
      this.auth.status = status;
    },
    updateAccessToken(token: string) {
      this.auth.token.access = token;
    },
    updateRefreshToken(token: string) {
      this.auth.token.refresh = token;
    },
    updateEmailStatus(status: boolean) {
      this.email.validated = status;
    },
    updateUserId(id: number) {
      this.profile.id = id;
    },
    updateJoinDate(date: number) {
      this.profile.join = date;
    },
    updateProMembershipStatus(status: boolean) {
      this.member.pro = status;
    },
    updateMembershipExpiry(date: number) {
      this.member.exp = date;
    },
    updateMaxMembership(limit: number) {
      this.member.max = limit;
    },
  },
});
