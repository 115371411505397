export interface UserEntity {
  authorization:  string;
  email:          string;
  emailValidated: boolean;
  id:             number;
  joinDate:       number;
  maxServer:      number;
  password:       string;
  pro:            boolean;
  proExpiryDate:  number;
  username:       string;
}

export class Convert {
  public static toUserEntity(json: object): UserEntity {
    return json as UserEntity;
  }

  public static userEntityToJson(value: UserEntity): string {
    return JSON.stringify(value, null, 2);
  }
}

