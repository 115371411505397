<script setup lang="ts">
import AppBar from "@/components/AppBar.vue";
import NavBar from "@/components/NavBar.vue";
import {onMounted, ref} from "vue";
import {useAppStore} from "@/store/app";
import Footer from "@/components/Footer.vue";

const appStore = useAppStore()


onMounted(() => {
  appStore.setLoading(false)
})

</script>

<template>
  <v-app>
    <app-bar />
    <div :class="$vuetify.display.mobile? 'mc1' : 'mc'">
      <router-view v-slot="{ Component }">
        <transition :name="$vuetify.display.mobile ? 'fade-transition' : 'fade'" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <nav-bar />
    <v-divider />
    <Footer />
  </v-app>
</template>

<style scoped>

</style>
