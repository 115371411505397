import { API } from "@/app/api/BaseAPI";
import {Convert, UserEntity} from "@/app/entity/UserEntity"

/**
 * A class for calling user api.
 *
 * The documentation of the api are below:
 * - [Get user information](https://wisdomme.gitbook.io/ultikits-panel/get-info-1/get-user-info)
 * - [Add user](https://wisdomme.gitbook.io/ultikits-panel/get-info-1/add-user-info-register)
 * - [Update user](https://wisdomme.gitbook.io/ultikits-panel/get-info-1/update-user-info)
 * - [Delete user](https://wisdomme.gitbook.io/ultikits-panel/get-info-1/delete-user)
 *
 * You can use this api to get, add, update, delete user.
 *
 * This api requires authentication, so you need to ensure that the user is logged in before using it.
 *
 * Some operations of this api require admin permission, so you need to ensure that the user has admin permission before using it.
 *
 * It based on the BaseAPI, so you can use the BaseAPI to do more operations.
 *
 * @class UserAPI
 * @author qianmo
 * @date 2023-07-02 20:56
 * @version 2.0.0
 * @since 2.0.0
 */
export class UserAPI {
  /** The endpoint of user api, which is used to get user information. */
  readonly userPath = "/user/"
  /** The endpoint of user api, which is used to add user. */
  readonly addPath = "/user/adduser"
  /** The endpoint of user api, which is used to update user. */
  readonly updatePath = "/user/updateuser/"
  /** The endpoint of user api, which is used to delete user. */
  readonly deletePath = "/user/deluser/"

  /**
   * Get user information
   *
   * You can use this function to get user information
   *
   * It will return a promise of UserEntity which contains the user information.
   *
   * The user information is an object of UserEntity.
   * @see UserEntity
   *
   * @param id user id
   *
   * @returns {Promise<any>} a promise of UserEntity
   */
  getUser(id: number|string): Promise<UserEntity> {
    const api = new API();

    return new Promise((resolve, reject) => {
      api
        .withPath(this.userPath + id)
        .withMethod("POST")
        .withToken()
        .request()
        .then((response) => {
          resolve(Convert.toUserEntity(response.data));
        }, (reason) => {
          reject(reason);
        });
    });
  }

  /**
   * Get user information with a given field
   *
   * This function is used to get user information with a specific field.
   *
   * @param id user id
   * @param field field name
   *
   * @returns {Promise<any>} a promise of user information, null if not found.
   */
  getUserField(id: number|string, field: string): Promise<string> {
    const api = new API();

    return new Promise((resolve, reject) => {
      api
        .withPath(this.userPath + id)
        .withParams({ field: field })
        .withMethod("POST")
        .withToken()
        .request()
        .then((response) => {
          resolve(response.data.data);
        }, (reason) => {
          reject(reason);
        });
    });
  }

  /**
   * Add user (register)
   *
   * user use this function to register
   *
   * It will return server response.
   *
   * There are three types of response:
   * - 200: success, the response looks like this: `{"result": "true"; "msg": "Operate successfully"}`
   * - 403: failed, the response is empty
   * - 500: failed, Internal Server Error
   *
   * @param username username
   * @param password password
   * @param email email
   *
   * @returns {Promise<any>} a promise of server response
   */
  addUser(username: string, password: string, email: string): Promise<any> {
    const api = new API();

    return new Promise((resolve, reject) => {
      api
        .withPath(this.addPath)
        .withParams({ username: username, password: password, email: email })
        .withMethod("POST")
        .request()
        .then((response) => {
          resolve(response.data);
        }, (reason) => {
          reject(reason);
        });
    });
  }

  /**
   * Update user information
   *
   * You can use this function to update a specific field of user information.
   *
   * So you can only update one field at a time.
   *
   * If success, the response looks like this: `{"code": 200, "msg": "Operate successfully!"}`
   *
   * @param id user id
   * @param username username
   * @param field field name
   * @param value field value
   *
   * @returns {Promise<any>} a promise of server response
   */
  updateUser(id: number|string, username: string, field: string, value: string): Promise<any> {
    const api = new API();

    return new Promise((resolve, reject) => {
      api
        .withPath(this.updatePath + id)
        .withParams({ [field]: value })
        .withMethod("POST")
        .withToken()
        .request()
        .then((response) => {
          resolve(response.data);
        }, (reason) => {
          reject(reason);
        });
    });
  }

  /**
   * Delete user
   *
   * You can use this function to delete user.
   *
   * **DANGER** You can only use this with highest permission.
   *
   * NEVER EVER USE THIS IF IT IS NOT NECESSARY.
   *
   * User information is protected under Information Privacy Law.
   *
   * @param id user id you want to delete
   */
  deleteUser(id: number|string): Promise<any> {
    const api = new API();

    return new Promise((resolve, reject) => {
      api
        .withPath(this.deletePath + id)
        .withMethod("POST")
        .withToken()
        .request()
        .then((response) => {
          resolve(response.data);
        }, (reason) => {
          reject(reason);
        });
    });
  }

  /**
   * Validate email
   *
   * You can use this function to validate email.
   *
   * code is optional. When sending a code, leave it null and when validating a code, pass the code.
   *
   * There is a 60 seconds CD (Cool Down) for sending a code, so you need to show the user a timer.
   *
   * It will return a promise of any type.
   *
   * If success, the response will be empty.
   *
   * @param id user id
   * @param code validation code, which sent to user's email
   *
   * @return Promise<any>
   */
  validateEmail(id: string|number, code?: string|number): Promise<any> {
    const api = new API();

    return new Promise((resolve, reject) => {
      api
        .withPath(`${this.userPath}${id}/email/validate`)
        .withParams(code ? { code: code } : {})
        .withMethod("POST")
        .withToken()
        .request()
        .then((response) => {
          resolve(response.data);
        }, (reason) => {
          reject(reason);
        });
    });
  }
}
