import * as vue from "vue";
import {Router} from "vue-router";
import {GuardConfig, setupGuard, StorageType} from "@this-dot/vue-route-guard";
import {useUserStore} from "@/store/user";
import {TokenAPI} from "@/app/api/TokenAPI";
import {UserAPI} from "@/app/api/UserAPI";
import {storeToken, storeUser} from "@/app/utils/store";
import { Crisp } from "crisp-sdk-web"

export function routerGuard (app: vue.App, router: Router) {

  const config: GuardConfig = {
    router: router,
    token: {
      name: 'access_token',
      storage: StorageType.localStorage
    },
    redirect: {
      noAuthentication: '/auth',
      clearAuthentication: '/auth/login',
      noPermission: '/no-permission',
    },
    options: {
      fetchAuthentication: async () => {
        const userStore = useUserStore();

        if (!localStorage.getItem('refresh_token') || localStorage.getItem('refresh_token') === 'undefined') {
          userStore.updateAuthStatus(false);
          console.log('Authentication failed: Refresh token not found');
          throw new Error('Authentication failed: Refresh token not found');
        }

        const tokenAPI = new TokenAPI();
        const userAPI = new UserAPI();

        try {
          const tokenEntity = await tokenAPI.refreshToken();
          storeToken(tokenEntity);
          const userId = localStorage.getItem('id');
          const userEntity = await userAPI.getUser(String(userId));
          storeUser(userEntity);
          userStore.updateAuthStatus(true);

          Crisp.setTokenId(userEntity.username)
          Crisp.user.setNickname(userEntity.username)
          Crisp.user.setEmail(userEntity.email)

          return {
            permissions: [userEntity.authorization],
          };
        } catch (error) {
          userStore.updateAuthStatus(false);
          throw new Error('Authentication failed: ' + (error as Error).message);
        }
      },
    },
  };

  app.use(setupGuard(config));
}
