<script setup lang="ts">
import {ref} from "vue";
import {useToast} from "vue-toastification";

import {useUserStore} from "@/store/user";
import {UserAPI} from "@/app/api/UserAPI";
import {useGuard} from "@this-dot/vue-route-guard";

const userStore = useUserStore()
const userAPI = new UserAPI()
const toast = useToast()
const auth = useGuard()

const dialog = ref(false)
const loading = ref(false)
const code = ref("")
const timer = ref(0)

const setTimer = () => {
  timer.value = 60
  const interval = setInterval(() => {
    timer.value--
    if (timer.value <= 0) {
      clearInterval(interval)
    }
  }, 1000)
}

const sendCode = async () => {
  if (timer.value > 0) {
    dialog.value = true
    return
  }
  try {
    loading.value = true
    await userAPI.validateEmail(userStore.getUserId)
    code.value = ""
    setTimer()
    dialog.value = true
  } catch (error) {
    toast.error("发送验证码失败：" + (error.response.data.msg ? error.response.data.msg : error))
  } finally {
    loading.value = false
  }
}
const validateCode = async () => {
  try {
    loading.value = true
    await userAPI.validateEmail(userStore.getUserId, code.value)
    toast.success("验证成功")
    dialog.value = false
    await auth.refreshAuthentication()
  } catch (error) {
    toast.error("验证失败：" + (error.response.data.msg ? error.response.data.msg : error))
  } finally {
    loading.value = false
  }
}
</script>

<template>
  <div v-if="!userStore.isEmailValidated">
    <v-row justify="center">
      <v-col cols="12" lg="6" md="8" sm="12">
        <v-alert color="warning" variant="tonal">
          验证电子邮箱，解锁更多功能
          <template v-slot:append>
            <v-btn variant="text" @click="sendCode" :loading="loading" :disabled="loading">开始验证</v-btn>
          </template>
        </v-alert>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500">
      <v-card class="text-center">
        <v-card-title class="mt-4">邮箱验证</v-card-title>
        <v-card-text>
          <p class="text-caption">验证码已发送到 {{ userStore.getEmail }}</p>
          <p class="text-caption">在下方输入你收到的6位验证码</p>
          <v-otp-input type="number" v-model="code" @finish="validateCode" />
          <v-btn class="mb-4" variant="text" @click="sendCode" :disabled="timer > 0 || loading" :loading="loading"> {{ timer > 0 ? timer + "s" : "重新发送" }} </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>

</style>
