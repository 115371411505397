<script setup lang="ts">
const version = process.env.APP_VERSION
const commit_hash = process.env.APP_GIT_HASH
const build_date = process.env.APP_BUILD_DATE
</script>

<template>
  <footer v-if="!$vuetify.display.mobile" class="my-4 mx-4 bg-transparent">
    <p class="float-left pr-4">
      <v-tooltip text="Tooltip" location="top">
        <template v-slot:activator="{ props }">
          <span v-bind="props" class="text-disabled">
            <v-icon size="small" start>mdi-alpha-v-box</v-icon>{{ version }}
            <v-icon size="small" class="ml-6" start>mdi-source-branch</v-icon>{{ commit_hash }}
          </span>
        </template>
        <span>Build Date: {{ build_date }}</span>
      </v-tooltip>
    </p>
    <p class="float-right text-disabled">Copyright &copy; 2019 - {{ new Date().getFullYear() }} UltiKits Dev Team</p>
  </footer>
</template>

<style scoped>

</style>
