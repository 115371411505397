// Utilities
import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
  state: () => ({
    bl: false,
    loading: false,
    redirect: "/",
    nav: true,
    last: "/",
  }),
  getters: {
    getBl: (state) => state.bl,
    isLoading: (state) => state.loading,
    getRedirect: (state) => state.redirect,
    getNav: (state) => state.nav,
    getLast: (state) => state.last
  },
  actions: {
    setBl(bl: boolean) {
      this.bl = bl
    },
    setLoading(loading: boolean) {
      this.loading = loading
    },
    setRedirect(redirect: string) {
      this.redirect = redirect
    },
    setNav(nav: boolean) {
      this.nav = nav
    },
    setLast(last: string) {
      this.last = last
    }
  }
})
