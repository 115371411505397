<script lang="ts" setup>
import {ref} from "vue";
import {useRouter} from "vue-router";

const dialog = ref(false);
const router = useRouter();

const openManager = () => {
  dialog.value = true
  setTimeout(() => {
    router.push({path: "/manager"})
  }, 1000)
}

const openDoc = () => {
  window.open('https://doc.ultikits.com', '_blank')
}

const openDev = () => {
  window.open('https://dev.ultikits.com', '_blank')
}
</script>

<template>
  <v-row>
    <v-dialog scrollable v-model="dialog" width="300" persistent>
      <v-card>
        <v-card-text class="text-center">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-progress-linear indeterminate />
              </v-col>
              <v-col cols="12">
                <p class="text-disabled">正在打开服务器管理面板...</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-col cols="12" lg="6" md="6" sm="12">
      <v-card
        flat
        class="mx-auto"
        max-width="400"
        @click="openDoc"
      >
        <v-img
          class="align-end text-white"
          height="200"
          src="https://pic.imgdb.cn/item/648d88dc1ddac507ccfc11a5.png"
          cover
        >
          <v-card-title class="font-weight-bold">用户使用文档</v-card-title>
          <v-card-subtitle class="pb-4">学习如何利用 UltiKits 拓展你的服务器</v-card-subtitle>
        </v-img>
      </v-card>
    </v-col>
    <v-col cols="12" lg="6" md="6" sm="12">
      <v-card
        flat
        class="mx-auto"
        max-width="400"
        @click="openDev"
      >
        <v-img
          class="align-end text-white"
          height="200"
          src="https://pic.imgdb.cn/item/648d8b841ddac507ccffc82f.png"
          cover
        >
          <v-card-title class="font-weight-bold">UltiTools API</v-card-title>
          <v-card-subtitle class="pb-4">使用高拓展性 API 重新定义 Spigot 插件开发</v-card-subtitle>
        </v-img>
      </v-card>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <h2>基础功能解决方案</h2>
    </v-col>
    <v-col cols="12" lg="6" md="6" sm="12">
      <v-banner class="elevation-0 border-0" lines="two">
        <template v-slot:prepend>
          <v-avatar class="rounded-sm" color="warning">
            <v-icon>mdi-toolbox</v-icon>
          </v-avatar>
        </template>
        <template v-slot:text>
          <h3>UltiTools</h3>30+ 基础/高级功能，一键搞定
        </template>
      </v-banner>
    </v-col>
    <v-col cols="12" lg="6" md="6" sm="12">
      <v-banner class="elevation-0 border-0" lines="two">
        <template v-slot:prepend>
          <v-avatar class="rounded-sm" color="indigo">
            <v-icon>mdi-atom</v-icon>
          </v-avatar>
        </template>
        <template v-slot:text>
          <h3>UltiCore</h3>强大的多版本兼容，内置各类API
        </template>
      </v-banner>
    </v-col>
    <v-col cols="12" lg="6" md="6" sm="12">
      <v-banner class="elevation-0 border-0" lines="two">
        <template v-slot:prepend>
          <v-avatar class="rounded-sm" color="purple">
            <v-icon>mdi-cash</v-icon>
          </v-avatar>
        </template>
        <template v-slot:text>
          <h3>UltiEconomy</h3>轻量级经济插件，支持数据库储存
        </template>
      </v-banner>
    </v-col>
    <v-col cols="12" lg="6" md="6" sm="12">
      <v-banner class="elevation-0 border-0" lines="two">
        <template v-slot:prepend>
          <v-avatar class="rounded-sm" color="cyan-darken-1">
            <v-icon>mdi-auto-fix</v-icon>
          </v-avatar>
        </template>
        <template v-slot:text>
          <h3>UltiLevel</h3>简约的RPG插件，娱乐服必备
        </template>
      </v-banner>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <h2>便捷工具</h2>
    </v-col>
    <v-col cols="12" lg="6" md="6" sm="12">
      <v-banner class="elevation-0 border-0" lines="two" @click="openManager">
        <template v-slot:prepend>
          <v-avatar class="rounded-sm" color="green">
            <v-icon color="white">mdi-wrench-cog</v-icon>
          </v-avatar>
        </template>
        <template v-slot:text>
          <h3 class="mb-2">网页配置器</h3>可视化的配置编辑器，告别YAML
        </template>
        <template v-slot:actions>
          <v-chip color="blue" text="Beta"/>
        </template>
      </v-banner>
    </v-col>
    <v-col cols="12" lg="6" md="6" sm="12">
      <v-banner class="elevation-0 border-0" lines="two">
        <template v-slot:prepend>
          <v-avatar class="rounded-sm" color="red">
            <v-icon color="white">mdi-view-dashboard</v-icon>
          </v-avatar>
        </template>
        <template v-slot:text>
          <h3 class="mb-2">网页管理器</h3>该功能仍在开发中，敬请期待
        </template>
        <template v-slot:actions>
          <v-chip color="red" text="Alpha"/>
        </template>
      </v-banner>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <h2>更多</h2>
    </v-col>
    <v-col cols="12" lg="6" md="6" sm="12">
      <v-banner class="elevation-0 border-0" lines="two">
        <template v-slot:prepend>
          <v-avatar class="rounded-sm" color="black">
            <v-icon color="white">mdi-github</v-icon>
          </v-avatar>
        </template>
        <template v-slot:text>
          <h3 class="mb-2">GitHub 官方组织</h3>查看 UltiKits 开发团队的项目
        </template>
      </v-banner>
    </v-col>
    <v-col cols="12" lg="6" md="6" sm="12">
      <v-banner class="elevation-0 border-0" lines="two">
        <template v-slot:prepend>
          <v-avatar class="rounded-sm" color="teal">
            <v-icon color="white">mdi-server</v-icon>
          </v-avatar>
        </template>
        <template v-slot:text>
          <h3 class="mb-2">服务状态</h3>查看 UltiKits 各项服务的状态
        </template>
      </v-banner>
    </v-col>
  </v-row>
</template>

<style scoped>

</style>
