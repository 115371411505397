import { useTheme, useLocale } from 'vuetify'
import { Crisp } from "crisp-sdk-web";

function initChat() {
  Crisp.configure("a37917e8-3291-4008-b7c9-4b069cdacce2", {
    sessionMerge: true,
  });
}

function initTheme() {
  const theme = useTheme();
  const storedTheme = localStorage.getItem('theme') || 'auto';

  if (storedTheme === 'auto') {
    const prefersDarkMode = window.matchMedia && window.matchMedia('prefers-color-scheme: dark').matches;
    theme.global.name.value = prefersDarkMode ? 'dark' : 'light';
  } else {
    theme.global.name.value = storedTheme;
  }

  if (localStorage.getItem('theme') === null) {
    localStorage.setItem('theme', theme.global.name.value);
  }
}

function initI18n() {
  const locale = useLocale()
  const storedLocale = localStorage.getItem('locale')

  if (storedLocale) {
    locale.current.value = storedLocale
  } else {
    locale.current.value = navigator.language.replaceAll('-', '_')
    localStorage.setItem('locale', navigator.language.replaceAll('-', '_'))
  }
}

export {initChat, initTheme, initI18n}
