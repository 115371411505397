/* eslint-disable no-useless-catch */
import { API } from "@/app/api/BaseAPI";
import {Convert, TokenEntity} from "@/app/entity/TokenEntity"

export class TokenAPI {
  readonly getPath = '/user/getToken';
  readonly refreshPath = '/user/refreshToken';

  async getToken(username: string, password: string): Promise<TokenEntity> {
    const api = new API();

    try {
      const response = await api
          .withPath(this.getPath)
          .withParams({username, password})
          .withMethod("POST")
          .request();
      if (response.data !== '') {
        return Convert.toTokenEntity(response.data);
      } else {
        throw new Error(response.data);
      }
    } catch (reason) {
      throw reason;
    }
  }


  async refreshToken(): Promise<TokenEntity> {
    const api = new API();

    try {
      const response = await api
          .withPath(this.refreshPath)
          .withParams({refresh_token: localStorage.getItem("refresh_token")})
          .withMethod("POST")
          .request();
      return Convert.toTokenEntity(response.data);
    } catch (reason) {
      throw reason;
    }
  }
}
