<script setup lang="ts">
import {ref} from "vue";

import {useAppStore} from "@/store/app";

const appStore = useAppStore()

const selected = ref("")

const navs = ref([
  {
    name: "主页",
    icon: "mdi-home-outline",
    selected: "mdi-home",
    path: "/",
  },
  {
    name: "服务器",
    icon: "mdi-dns-outline",
    selected: "mdi-dns",
    path: "/server",
  },
  {
    name: "会员",
    icon: "mdi-crown-outline",
    selected: "mdi-crown",
    path: "/member",
  },
  {
    name: "模块库",
    icon: "mdi-puzzle-outline",
    selected: "mdi-puzzle",
    path: "/module",
  },
  {
    name: "开发者",
    icon: "mdi-file-code-outline",
    selected: "mdi-file-code",
    path: "/developer",
  },
])
</script>

<template>
  <v-bottom-navigation :active="appStore.getNav && $vuetify.display.mobile" v-model="selected" mandatory fixed height="80">
    <v-btn size="x-small" :to="nav.path" v-for="nav in navs" :key="nav.name" :value="nav.name" class="nav-btn ml-1 mr-1" :ripple="false">
      <v-icon size="24">{{ selected === nav.name ? nav.selected : nav.icon }}</v-icon>
      <span :class="selected === nav.name ? 'font-weight-bold' : ''">{{ nav.name }}</span>
    </v-btn>
  </v-bottom-navigation>
</template>

<style scoped>

</style>
