<template>
  <div>
    <loading-overlay />
    <router-view />
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import { getCurrentInstance, ComponentInternalInstance } from "vue";
import { useGuard } from '@this-dot/vue-route-guard';
import {useRoute, useRouter} from 'vue-router'
import {useToast} from "vue-toastification";

import {useAppStore} from "@/store/app";
import {initI18n, initTheme, initChat} from "@/app/utils/init";
import getPath from "@/app/utils/path";

import LoadingOverlay from "@/components/LoadingOverlay.vue";
import {useUserStore} from "@/store/user";
import {useSocketStore} from "@/store/sock";

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()
const socketStore = useSocketStore()
const appStore = useAppStore()
const auth = useGuard()
const toast = useToast()

const { proxy } = getCurrentInstance() as ComponentInternalInstance;

let timer = 0;

onMounted(() => {

  const pathPattern = /^\/auth/;

  const path = pathPattern.test(getPath())? "/" : getPath()

  appStore.setRedirect(path)

  appStore.setLoading(true)
  auth.refreshAuthentication()
    .then(() => {
      if (!auth.isAuthenticated()) {
        router.push({path: '/auth/login'})
      }
      if (!route.meta.requiresAuth) {
        let params = {};
        if(appStore.getRedirect.indexOf("?") != -1){
          params = Object.fromEntries(new URLSearchParams(appStore.getRedirect.split("?")[1]).entries())
        }
        router.push({path: appStore.getRedirect, query: params})
      }
    })
    .catch(() => {
      router.push({path: '/auth/login'})
    }).finally(() => {
      appStore.setLoading(false)
    });
  initTheme()
  initI18n()
  initChat()
})

/** 目前还是有一些问题，下次再改
userStore.$subscribe((mutation, state) => {
    if (state.auth.status) {
        proxy?.$connect(`wss://api.ultikits.com/ws?token=${localStorage.getItem('access_token')}`)
    } else {
        proxy?.$disconnect("")
    }
})

socketStore.$subscribe((mutation, state) => {
  if (state.isConnected) {
    clearInterval(timer)
    return
  }
  let count = 0
  if (count >= 5) {
    clearInterval(timer)
  }
  //toast.warning('连接断开，正在尝试重新连接服务器');
  timer = window.setInterval(() => {
    count ++
    auth.refreshAuthentication().then(
      proxy?.$connect(`wss://api.ultikits.com/ws?token=${localStorage.getItem('access_token')}`)
    )
  }, 3000)
})
 **/
</script>
