export default {
  app: {
    auth: {
      welcome: '欢迎回来',
      username: '用户名',
      password: '密码',
      register: '注册账号',
      forget: '忘记密码',
      email: '电子邮箱',
      pwdRepeat: '重复密码',
      backLogin: '返回登录',
      findAccount: '找回账号',
      account: '账号',
      usernameOrEmail:'用户名或电子邮箱',
      plzInputAccount:'请输入要找回的账号',
      verify: '验证',
      codeVerify: '身份验证',
      codeSendTo: '验证码已发送至',
      codeSent: '验证码已发送，请注意查收',
      codeSendFail: '发送验证码失败：',
      reSend: '重新发送',
      codeVerified: '身份验证成功',
      verifyFailure: '验证失败：',
      resetPwd: '重置密码',
      newPwd: '新密码',
      newPwdRepeat: '重复新密码',
      verifyExpire: '身份验证的有效期至',
      resetHint: '请在此之前完成密码重置操作',
      resetSuccess: '密码重置成功, 请使用新密码登录',
      resetFailure: '密码重置失败：',
      requiredField: '此为必填项',
      reset: '重置',
      pwdRequireMoreThan: '密码不能少于 {0} 个字符',
      pwdRequireOnlyAscii: '密码只能包含字母、数字和下划线',
      pwdNotMatch: '两次输入的密码不一致',
      subTitle: 'Spigot 服务器插件管理平台',
      profileFailure: '登录失败：无法获取用户信息，请稍后再试',
      loginSuccess: '登录成功：欢迎回来，',
      loginFailure: '登录失败：',
      badCredential: '登录失败：用户名或密码错误',
      usernameRequireMoreThan: '用户名不能少于 {0} 个字符',
      usernameRequireLessThan: '用户名不能多于 {0} 个字符',
      usernameRequireOnlyAscii: '用户名只能包含字母、数字和下划线',
      requireAvailableEmail: '请输入有效的电子邮箱',
      registerSuccess: '注册成功，请登录',
      registerFailure: '注册失败：',
    },
    appBar: {
      server: '服务器',
      member: '会员',
      module: '模块库',
      dev: '开发者中心',
      profile: '个人中心',
      logout: '退出登录',
      logoutMsg: '已退出登录',
    }
  },
  badge: '徽章',
  open: '打开',
  close: '关闭',
  dataIterator: {
    noResultsText: '没有符合条件的结果',
    loadingText: '加载中……'
  },
  dataTable: {
    itemsPerPageText: '每页数目：',
    ariaLabel: {
      sortDescending: '：降序排列。',
      sortAscending: '：升序排列。',
      sortNone: '：未排序。',
      activateNone: '点击以移除排序。',
      activateDescending: '点击以降序排列。',
      activateAscending: '点击以升序排列。'
    },
    sortBy: '排序方式'
  },
  dataFooter: {
    itemsPerPageText: '每页数目：',
    itemsPerPageAll: '全部',
    nextPage: '下一页',
    prevPage: '上一页',
    firstPage: '首页',
    lastPage: '尾页',
    pageText: '{0}-{1} 共 {2}'
  },
  dateRangeInput: {
    divider: '至'
  },
  datePicker: {
    ok: '确定',
    cancel: '取消',
    range: {
      title: '选择日期',
      header: '输入日期'
    },
    title: '选择日期',
    header: '输入日期',
    input: {
      placeholder: '输入日期'
    }
  },
  noDataText: '没有数据',
  carousel: {
    prev: '上一张',
    next: '下一张',
    ariaLabel: {
      delimiter: '轮播 {0} 共 {1}'
    }
  },
  calendar: {
    moreEvents: '还有 {0} 项'
  },
  input: {
    clear: '清除 {0}',
    prependAction: '{0} 前置操作',
    appendAction: '{0} 后置操作',
    otp: '请输入 OTP 字符 {0}'
  },
  fileInput: {
    counter: '{0} 个文件',
    counterSize: '{0} 个文件（共 {1}）'
  },
  timePicker: {
    am: '上午',
    pm: '下午'
  },
  pagination: {
    ariaLabel: {
      root: '分页导航',
      next: '下一页',
      previous: '上一页',
      page: '转到页面 {0}',
      currentPage: '当前页 {0}',
      first: '第一页',
      last: '最后一页'
    }
  },
  stepper: {
    next: '下一步',
    prev: '上一步'
  },
  rating: {
    ariaLabel: {
      item: '评分 {0} 共 {1}'
    }
  },
  loading: '加载中...',
  infiniteScroll: {
    loadMore: '加载更多',
    empty: '没有更多了'
  }
};
